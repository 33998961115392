<template>
  <div class="page" :style="height">
    <div class="searchbar">
      <div class="action-wrapper pr10">
        <div class="inline-actions">
          <el-button
            type="primary"
            size="small"
            class="table-toolbar-btn"
            @click="add"
            v-if="perms.includes('video:add')"
          > 新增 </el-button>
          <el-button
            type="primary"
            size="small"
            class="table-toolbar-btn"
            @click="handleExport"
            v-if="perms.includes('video:export')"
          > 导出 </el-button>
          <el-button
            type="primary"
            size="small"
            class="table-toolbar-btn"
            :class="query.publishFlag === 0 ? '' : 'default'"
            @click="togglePublish"
            v-if="perms.includes('video:publish')"
          > 待发布 </el-button>
        </div>
        <div class="inline-actions">
          <el-button
            v-if="perms.includes('home:video')"
            type="primary"
            size="small"
            :class="query.sortType === 1 ? 'active' : ''"
            class="table-toolbar-btn default"
            @click="query.sortType = 1"
          > 时间 </el-button>
          <el-button
            v-if="perms.includes('home:video')"
            type="primary"
            size="small"
            :class="query.sortType === 2 ? 'active' : ''"
            class="table-toolbar-btn default"
            @click="query.sortType = 2"
          > 点击量 </el-button>
        </div>
      </div>

      <el-form ref="form" :model="query" :inline="true" label-width="74px" size="small" :show-message="false"
        class="search-form">
        <el-form-item label="关键字">
          <el-input v-model="query.searchKey" clearable placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="产品名称">
          <el-cascader
            :options="machineOptions"
            :props="machineProps"
            :show-all-levels="false"
            v-model="query.deviceTypeId"
            filterable
            clearable
            placeholder="请选择产品"
            class="full-width font14">
          </el-cascader>
        </el-form-item>
        <el-form-item label="机型名称">
          <el-select v-model="query.deviceNameId" filterable clearable placeholder="全部">
            <el-option
              v-for="option in deviceNameOptions"
              :key="option.id"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
        <el-button class="search-btn" @click="clearAndLoad"></el-button>
      </el-form>
    </div>

    <div class="main-content">
      <div v-if="videoTopList.length > 0" v-loading="topLoading" class="videos-top">
        <div v-for="(item, index) of videoTopList" :key="index" class="video-item">
          <div class="video-item__content">
            <VideoItem
              :video="item"
              top
              :params="query"
              @onAddTop="addTop(item)"
              @onRemoveTop="removeTop(item)"
              @onDelete="del(item)"
            ></VideoItem>
          </div>
        </div>
      </div>
      <template v-if="inGroupSearch">
        <div v-loading="groupLoading" class="videos-group-container">
          <template v-if="videoGroupList.length > 0">
            <div v-for="(group, index) of videoGroupList" :key="index" class="slider-item">
              <VideoSlider :key="index" :group="group" :params="query"></VideoSlider>
            </div>
          </template>
          <template v-else>
            <div class="group-empty"> 暂无数据 </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div v-loading="!haveFetchOnce && loading" class="videos-container">
          <div v-for="(item, index) of videoList" :key="index" class="video-item">
            <div class="video-item__content">
              <VideoItem
                :video="item"
                :params="query"
                @onAddTop="addTop(item)"
                @onRemoveTop="removeTop(item)"
                @onDelete="del(item)"
              ></VideoItem>
            </div>
          </div>
        </div>
        <div class="load-wrapper">
          <span v-if="loading"> 加载中... </span>
          <template v-else>
            <span v-if="videoList.length === 0"> 暂无数据 </span>
            <span v-else-if="loadFinished"> 没有更多了 </span>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils/debounce'
import VideoItem from '@/components/VideoItem'
import VideoSlider from '@/components/VideoSlider'

export default {
  components: { VideoItem, VideoSlider },
  data() {
    return {
      // 权限
      perms: JSON.parse(window.sessionStorage.getItem('perms')),
      // 查询参数
      query: {
        searchKey: '',
        deviceTypeId: '',
        deviceNameId: '',
        // 排序类型 1: 时间; 2: 点击量;
        sortType: 1,
        publishFlag: '',
        pageNo: 1,
        pageSize: 20
      },
      // 置顶视频数据
      videoTopList: [],
      // 视频分页数据
      videoList: [],
      // 视频分组列表
      videoGroupList: [],
      total: 0,
      // 是否已经获取过一次分页数据
      haveFetchOnce: false,
      // 分页数据加载状态
      loading: false,
      // 分组数据加载状态
      groupLoading: false,
      // 置顶数据加载状态
      topLoading: false,
      // 分页数据加载完毕
      loadFinished: false,
      // 是否在进行分组搜索
      inGroupSearch: false,
      // 机种分类选项
      machineOptions: [],
      // 机型名称选项
      deviceNameOptions: [],
      // 机型选项
      machineProps: {
        multiple: false,
        emitPath: false,
        value: 'id',
        label: 'name',
        children: 'children',
        disabled: 'disabled'
      },
      height: ''
    }
  },
  computed: {
    deviceTypeId() {
      return this.query.deviceTypeId
    },
    sortType() {
      return this.query.sortType
    }
  },
  watch: {
    machineOptions(options) {
      // 级联选择器只选择到[机型分类]，所以修改[机型分类]数据中的 children 键名
      for (const modelOption of options) {
        for (const deviceOption of modelOption.children) {
          deviceOption.options = deviceOption.children 
          delete deviceOption.children
        }
      }

      if (this.deviceTypeId) {
        let options = []
        for (const modelOption of this.machineOptions) {
          for (const deviceOption of modelOption.children) {
            if (deviceOption.id === this.deviceTypeId) {
              options = deviceOption.options
            }
          }
        }
        this.deviceNameOptions = options
      }
    },
    deviceTypeId(val) {
      // this.query.deviceNameId = ''

      let options = []
      for (const modelOption of this.machineOptions) {
        for (const deviceOption of modelOption.children) {
          if (deviceOption.id === val) {
            options = deviceOption.options

            const deviceNameInOption = false
            for (const option of options) {
              if (option.id === this.query.deviceNameId) {
                deviceNameInOption = true
                break
              }
            }

            if (!deviceNameInOption) this.query.deviceNameId = ''
          }
        }
      }
      this.deviceNameOptions = options
    },
    inGroupSearch(val) {
      this.clear()
    },
    sortType(val) {
      console.log('sort type changed. clear and load...')
      this.clearAndLoad()
    }
  },
  created() {
    console.log('video list page params: ', this.$route.params)
    this.query.searchKey = this.$route.params.searchKey || ''
    this.query.deviceTypeId = this.$route.params.deviceTypeId || ''
    this.query.deviceNameId = this.$route.params.deviceNameId || ''
    this.query.sortType = this.$route.params.sortType || 1
    this.query.publishFlag = this.$route.params.publishFlag === 0 ? 0 : ''

    this.$nextTick(() => {
      this.query.deviceNameId = this.$route.params.deviceNameId || ''
    })

    this.getModelDevice()
    this.getList()
    this.getTopList()
    
    this.height = 'min-height: ' + (window.innerHeight - 180) + 'px'

    // 监听滚动事件
    document.addEventListener('mousewheel', this.onMouseWheel)
    let that = this
    window.onscroll = function() {
      that.onMouseWheel()
    }
    
  },
  destroyed() {
    // 取消监听滚动事件
    document.removeEventListener('mousewheel', this.onMouseWheel)
    window.onscroll = undefined
  },
  methods: {
    // 重置查询条件
    handleReset() {
      this.query = {
        searchKey: '',
        deviceTypeId: '',
        deviceNameId: '',
        sortType: 1,
        pageNo: 1,
        pageSize: 20
      }
      this.clearAndLoad()
    },
    // 切换待发布 / 全部
    togglePublish() {
      this.query.publishFlag = this.query.publishFlag === 0 ? '' : 0
      this.clearAndLoad()
    },
    // 获取分页数据
    getList(page) {
      if (this.loadFinished) return

      // 是否分组查询设置
      this.inGroupSearch = Boolean(this.query.deviceTypeId)

      if (page) this.query.pageNo = page
      let that = this

      if (this.inGroupSearch) {
        this.groupLoading = true
        that.$request.post(
          "dataVideoGroupList",
          false,
          that.query,
          function (r) {
            if (r.code == "0") {
              that.videoGroupList = r.data
            } else {
              that.$message.error(r.msg)
            }
            that.groupLoading = false
          },
          function () {
            that.groupLoading = false
          }
        )
      } else {
        that.loading = true
        that.$request.post(
          "dataVideoPageList",
          false,
          that.query,
          function (r) {
            if (r.code == "0") {
              const list = that.videoList
              that.videoList = list.concat(r.data.list)

              if (that.query.pageNo >= r.data.totalPage) {
                that.loadFinished = true
              }

              that.haveFetchOnce = true
              that.query.pageNo++
            } else {
              that.$message.error(r.msg)
            }
            that.loading = false
          },
          function () {
            that.loading = false
          }
        )
      }
    },
    // 获取置顶视频数据
    getTopList() {
      console.log('get top list, query.deviceNameId ', this.query.deviceNameId)
      let that = this
      that.topLoading = true
      that.$request.post(
        "dataVideoTopList",
        false,
        that.query,
        function (r) {
          if (r.code == "0") {
            that.videoTopList = r.data
          } else {
            that.$message.error(r.msg)
          }
          that.topLoading = false
        },
        function() {
          that.topLoading = false
        }
      )
    },
    // 获取机种分类
    getModelDevice() {
      let that = this
      that.$request.post(
        "sysModelDeviceAll",
        true, {},
        function (r) {
          if (r.code == "0") {
            that.machineOptions = r.data
          } else {
            that.$message.error(r.msg);
          }
        }
      )
    },
    clear() {
      this.videoList = []
      this.query.pageNo = 1
      this.haveFetchOnce = false
      this.loadFinished = false
    },
    clearAndLoad() {
      console.log('clear and load...')
      this.clear()
      this.getList()
      this.getTopList()
    },
    // 新增
    add() {
      this.$router.push({
        name: 'videoAddEdit',
        params: this.query
      })
    },
    // 删除
    del(row) {
      this.$confirm('是否删除当前视频?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let that = this
        that.$request.post(
          'dataVideoDelete',
          true, {
            id: row.id
          },
          function (r) {
            if (r.code == "0") {
              that.clearAndLoad()
              that.$message.success('删除成功')
            } else {
              that.$message.error(r.msg)
            }
          }
        )
      }).catch(() => {})
    },
    // 置顶
    addTop(item) {
      let that = this
      that.$request.post(
        "dataVideoAddTop",
        false,
        {
          id: item.id,
          topFlag: "1"
        },
        function (r) {
          if (r.code == "0") {
            that.clearAndLoad()
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    },
    // 取消置顶
    removeTop(item) {
      let that = this
      that.$request.post(
        "dataVideoAddTop",
        false,
        {
          id: item.id,
          topFlag: "0"
        },
        function (r) {
          if (r.code == "0") {
            that.clearAndLoad()
          } else {
            that.$message.error(r.msg)
          }
        }
      )
    },
    handleExport() {
      let that = this
      that.$request.post(
        "dataVideoExport",
        true,
        that.query,
        function (res) {
          if (res.code == "0") {
            const linkNode = document.createElement('a');
            linkNode.style.display = 'none';
            linkNode.href = res.data;
            document.body.appendChild(linkNode);
            linkNode.click();  //模拟在按钮上的一次鼠标单击
            URL.revokeObjectURL(linkNode.href); // 释放URL 对象
            document.body.removeChild(linkNode);
          } else {
            that.$message.error(res.msg)
          }
        }
      )
    },
    onMouseWheel: debounce(function() {
      if (this.inGroupSearch) return

      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.documentElement.scrollHeight

      // 加载触发缓冲高度
      // 例如 =100 时， 表示离底端100px时就触发触底加载的操作
      const bufferHeight = 100

      if (scrollTop + clientHeight + bufferHeight >= scrollHeight) {
        // console.log('鼠标滚动时，监测到页面已触底，加载数据')
        this.getList()
      }
    }, 500),
  }
}
</script>

<style lang="scss" scoped>
.page {
  background-color: white;
  min-height: 500px;
}
.action-wrapper {
  display: flex;
  flex-direction: column;

  .inline-actions {
    display: flex;

    .table-toolbar-btn {
      width: unset;

      &.default {
        // background-color: rgba(186, 186, 186, .2);
        background-color: white;
        border: 1px solid rgba(186, 186, 186, .2);
        color: rgba(110, 110, 110, 1);
        // border: unset;
      }
      &.active {
        background-color: rgba(17, 127, 252, .2);
        color: rgba(17, 127, 252, 1);
      }
    }
  }
}

@media screen and (max-width: 1608px) {
  .search-form {
    max-width: 666px;
  }
}

@media screen and (max-width: 1000px) {
  .search-form {
    max-width: 444px;
  }
}

@media screen and (max-width: 723px) {
  .search-form {
    max-width: 222px;
  }
}

.search-btn{
  // float: unset !important;
  border: none;
  padding: 0 !important;
  margin: 0 !important;
  margin-left: 10px !important;
}
.refresh-btn{
  float: right;
  width: 32px;
  height: 32px;
  border: none;
  color: #117FFC;
  padding: 0;
  background-color: rgba(17, 127, 252, 0.2);
  font-weight: bold;
  font-size: 22px;
  vertical-align: 8px;
  margin-left: 5px;
  &:hover{
    background-color:  rgba(17, 127, 252, 0.3);
  }
}
.main-content {
  width: 100%;

  .videos-top {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(216, 216, 216, 1);
    margin-bottom: 10px;
    padding-bottom: 10px;
  }  
  .videos-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 100px;
  }

  .videos-top, .videos-container {
    width: 100%;

    .video-item {
      width: 25%;

      .video-item__content {
        padding: 6px;

        .video-item-wrapper {
          width: 100%;
          height: 160px;
        }
      }
    }
  }
  .videos-group-container {
    .slider-item {
      .video-item-wrapper {}
    }
    .group-empty {
      height: 100px;
      line-height: 100px;
      text-align: center;
      font-size: 14px;
    }
  }
  .load-wrapper {
    text-align: center;
    padding: 10px 0;
    color: #999999;
    font-size: 13px;
  }
}
</style>
