<template>
  <div class="video-slider">
    <div class="slider-title"> {{ groupName }} </div>
    <div class="slider-container" :style="sliderStyle">
      <div v-if="showArrow" class="slider-actions">
        <div class="slider-prev" @click="prev">
          <img src="@/assets/images/prev.png" />
        </div>
        <div class="slider-next" @click="next">
          <img src="@/assets/images/next.png" />
        </div>
      </div>
      <div
        ref="content"
        class="slider-content grab"
      >
        <!-- @mousedown.stop="onMouseDown"
        @mousemove.stop="onMouseMove"
        @mouseup.stop="onMouseUp" -->
        <div ref="slider" class="slider-content__body">
          <VideoItem ref="items" v-for="(video, index) of videos" :key="index" :video="video" :style="videoStyle" :params="params"></VideoItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoItem from '@/components/VideoItem'

export default {
  name: 'VideoSlider',
  components: { VideoItem },
  props: {
    group: {
      type: Object,
      required: true
    },
    // 视频列表页的查询参数
    params: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // 往左往右的移动距离(px)
      // scrollStep: 300,
      // 鼠标是否已按下
      mousePressed: false,
      // 鼠标按下开始的水平位置
      pressPointX: 0,
      // 鼠标按下时的内容水平移动位置
      contentXAtPress: 0,
      contentWidth: 0,
      sliderWidth: 0,
    }
  },
  computed: {
    // 分组名称
    groupName() {
      let name = ''
      if (this.group && this.group.deviceName) name = this.group.deviceName
      return name
    },
    // 分组视频
    videos() {
      let _videos = []
      if (this.group && this.group.dataVideoList) _videos = this.group.dataVideoList
      return _videos
    },
    sliderContainerHieght() {
      return this.videoHeight + 17
    },
    videoWidth() {
      if (this.contentWidth) {
        // 平铺状态下 每个视频元素的水平padding
        // const videoItemPaddingX = 6 * 2
        const videoItemPaddingX = 0

        return (this.contentWidth - (videoItemPaddingX * 4)) / 4
      }
      return 260
    },
    videoHeight() {
      // 每个视频元素的高宽比
      const ratio = 169 / 260
      return this.videoWidth * ratio
    },
    videoStyle() {
      if (this.videoWidth) {
        return `width: ${this.videoWidth}px; height: ${this.videoHeight}px;`
      }
      return ''
    },
    sliderStyle() {
      if (this.sliderContainerHieght) {
        return `height: ${this.sliderContainerHieght}px;`
      }
      return ''
    },
    showArrow() {
      // return this.sliderWidth > this.contentWidth
      return this.videos.length > 4
    }
  },
  created() {
    // let that = this
    // window.onmouseup = function() {
    //   console.log('window:鼠标释放')
    //   that.mousePressed = false
    // }

    window.addEventListener('resize', this.recalcSize)
  },
  mounted() {
    this.recalcSize()
  },
  destroyed() {
    // window.onmouseup = null
  },
  methods: {
    // 获取单个元素的宽
    getItemWidth() {
      let offset = 200 
      const items = this.$refs.items
      if (items) {
        const firstItem = items[0]
        offset = firstItem.$el.offsetWidth
      }
      return offset
    },
    // 往左移动
    prev() {
      if (this.$refs && this.$refs.content) {
        const scrollLeft = this.$refs.content.scrollLeft
        // const left = scrollLeft - this.scrollStep
        const left = scrollLeft - this.getItemWidth()

        this.$refs.content.scrollTo({
          left: left,
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    // 往右移动
    next() {
      if (this.$refs && this.$refs.content) {
        const scrollLeft = this.$refs.content.scrollLeft
        // const left = scrollLeft + this.scrollStep
        const left = scrollLeft + this.getItemWidth()

        // if (this.$refs.content.offsetWidth - left < this.getItemWidth()) {
        //   left = this.$refs.content.offsetWidth
        // }

        this.$refs.content.scrollTo({
          left: left,
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    // 鼠标移动
    onMouseMove(e) {
      if (this.mousePressed) {
        const positionX = e.clientX
        const offsetX = positionX - this.pressPointX

        if (this.contentXAtPress >= 0) {
          const left = this.contentXAtPress - offsetX
          this.$refs.content.scrollTo({
            left: left,
            top: 0,
            // behavior: 'smooth'
          })
        }
      }
    },
    // 鼠标按下
    onMouseDown(e) {
      this.mousePressed = true
      this.pressPointX = e.clientX
      if (this.$refs && this.$refs.content) {
        this.contentXAtPress = this.$refs.content.scrollLeft
      } else {
        this.contentXAtPress = -1
      }
    },
    // 鼠标松开
    onMouseUp(e) {
      this.mousePressed = false
    },
    recalcSize() {
      if (this.$refs.content) {
        this.$nextTick(() => {
          this.contentWidth = this.$refs.content.offsetWidth
          this.sliderWidth = this.$refs.slider.offsetWidth
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.video-slider {
  scroll-behavior: smooth;
  margin-bottom: 10px;

  &:hover {
    .slider-actions {
      opacity: 1;
    }
  }
  .slider-title {
    padding: 5px 0;
    font-size: 18px;
  }
  .slider-container {
    position: relative;
    min-height: 1;
    height: 180px;
    overflow: hidden;
  }
  .slider-actions {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    // opacity: 0;
    opacity: 1;

    .slider-prev, .slider-next {
      cursor: pointer;
      z-index: 21;
    }
  }
  .slider-content {
    overflow-x: scroll;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -17px;
    // cursor: grab;

    // &.grabbing {
    //   .video-item-wrapper {
    //     cursor: grabbing;
    //   }
    // }
    // &.grab {
    //   .video-item-wrapper {
    //     cursor: grab;
    //   }
    // }
    .slider-content__body {
      display: inline-flex;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
      // bottom: -17px;

      // :deep(.video-item-wrapper) {
      //   // height: 60px;
      //   // width: 260px;
      //   // height: 169px;
      //   // cursor: grabbing;
      // }
      // .video-item-wrapper {
      //   cursor: grab;
      // }
    }
  }
}
</style>
